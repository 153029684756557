<template>
    <b-container>      
      <b-modal ref="modal" hide-footer hide-header-close title="">
        <div class="d-block text-center">
          <h3 class="text-danger">{{modalText}}</h3>
        </div>
        <div class="d-block text-center" v-if="isModalClosable">
          <b-button @click="closeModal">Ok</b-button>
        </div>
      </b-modal>
      <b-row v-if="!isMobile()">
        <b-col>
          <b-form-input
              id="textarea"
              type="number"
              v-model="postcode"
              :formatter="postcodeFormat"
              placeholder="Bitte Postleitzahl eingeben"
              no-resize              
              ></b-form-input>
        </b-col>
        <b-col>
          <b-button @click="downloadExcelByPostcode">Exportieren</b-button>
        </b-col>
      </b-row>
      <b-row v-if="!isMobile()">
        <b-col>
          <b-form-input
              id="textarea"
              v-model="areaId"
              placeholder="Bitte Gebiets-ID eingeben"
              no-resize              
              ></b-form-input>
        </b-col>
        <b-col>
          <b-button @click="downloadExcelByAreaId">Exportieren</b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <div>Diese Funktion ist noch nicht für Mobilgeräte verfügbar.</div>
      </b-row>
    </b-container>
</template>

<script>

import api from '../../api/kpi-energie-api/sperrliste'

export default {
  name: 'PageSperrgebieteExport',
  components: {
  },
  props: [
  ],
  data () {
    return {
      'postcode': null,
      'areaId': null,
      'isModalClosable': false,
      'modalText': 'Bitte warten, der Export wird erstellt...'
    }
  },
  methods: {
    postcodeFormat(e) {
      const _e = e.replace(/[^0-9]/g, "")
      return String(_e).substring(0,5);
    },
    showModal(modalText, isClosable) {
      this.modalText = modalText
      this.isModalClosable = isClosable
      this.$refs['modal'].show()
    },
    closeModal() {
      this.$refs['modal'].hide()
      this.isModalClosable = false
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    isNotMobile() {
      return !this.isMobile()
    },
    downloadExcelByPostcode() {
      if (!this.postcode || this.postcode.length !== 5) {
        this.showModal('Die Postleitzahl muss genau 5 Ziffern lang sein!', true)
        return
      }
      this.downloadExcel(this.postcode, true);
    },
    downloadExcelByAreaId() {
      this.downloadExcel(this.areaId, false);
    },
    downloadExcel(text, isByPostcode) {
      this.showModal('Bitte warten, der Export wird erstellt...', false);
      (isByPostcode === true ? api.sperrlistenDownloadByPostcode(text) : api.sperrlistenDownloadAreaId(text)).then((response) => {
          const blob = new Blob([response.data])
          const urlBlob = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = urlBlob
          a.download = 'export.xlsx'
          a.click()
          window.URL.revokeObjectURL(urlBlob)
          this.closeModal()
        })
        .catch((error) => {
          const errorMessage = error.status == 401 ? 'Deine Session ist abgelaufen, bitte erneut anmelden.' : 'Fehler beim Herunterladen der Datei: ' + error;
          this.closeModal()
          this.showModal(errorMessage, true)
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>