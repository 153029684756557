import axios from 'axios'
import store from '../../store'

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL ? process.env.VUE_APP_API_BASEURL : (window.location.protocol + '//' + window.location.host + '/api/v1'),
  timeout: 600 * 1000, // timeout in milliseconds
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType:'arraybuffer'
})

HTTP.interceptors.request.use(config => {
  let token = store.state.user.token
  if (!token && process.env.VUE_APP_TOKEN && process.env.NODE_ENV !== 'production') {
    token = process.env.VUE_APP_TOKEN
  }
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}, error => Promise.reject(error))

export default {
  sperrlistenDownloadByPostcode (postcode) {
    return HTTP.post(`/kpi/restrictedarea/byPostcode`, JSON.stringify({postcode}),
    { 
      headers: {
      'Content-Type': 'application/json'
    }})
  },
  sperrlistenDownloadAreaId (areaId) {
    return HTTP.post(`/kpi/restrictedarea/byAreaId`, JSON.stringify({areaId}),
    { 
      headers: {
      'Content-Type': 'application/json'
    }})
  },
  isAllowed () {
    return HTTP.get(`/kpi/restrictedarea`)
  }
}
